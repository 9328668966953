import { defineStore } from "pinia";
export const useAlertStore = defineStore({
    id: "alert",
    state: () => ({
        type: "",
        message: "",
        show : false
    }),
    actions: {
        setAlertMessage(type, message , show) {
            this.type = type
            this.message = message
            this.show =  show
        },
    }
})