<template>
    <v-container>
        <v-row>
        <v-col cols="12" md="12">
            <v-skeleton-loader
            class="mx-auto border"
            type="image, article"
            ></v-skeleton-loader>
        </v-col>
        </v-row>
    </v-container>
</template>