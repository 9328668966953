import { defineStore } from "pinia";
import { axiosInstance, scbAxiosInstance } from "../services";
import { formatfilterOptions } from "../misc/formatter";

const initialState = {
    priceRange: [0, 12000000],
    sortBy: "+end_date",
    search: "",
    category_id: [],
    sub_categories: [],
    location: [],
    condition: [],
    selectedSortBy : "Sort by: Time Left",
    filterBy : "",
    categories_options : [],
    conditions_options : [],
    location_options : [],
    sub_categories_options : [],
}
export const useFilter = defineStore({
    id: "FilterStore",
    state: () => ({
        biddingMode: "Hybrid",
        ...initialState
    }),
    actions: {
        resetFilters() {
            this.priceRange = [0, 12000000]
            this.sortBy = "+end_date"
            this.search = ""
            this.category_id = []
            this.sub_categories =[]
            this.location = []
            this.condition =[]
            this.selectedSortBy = "Sort by: Time Left"
            this.filterBy = ""
        },
        setPriceRange(val) {
            this.priceRange = val;
        },
        setFilterBy(stateName, value) {
            if(stateName === "biddingMode"){
                this.biddingMode =  value
                for(let state of Object.keys(initialState)){
                    this[state] =  initialState[state]
                }
            }else if(stateName === "BiddingMode"){
                this.biddingMode =  value
            }
            else{
                this.filterBy = stateName
                this[stateName] = value
            }
          
        },
        lotFilterOptions(type = 'hybrid'){
            axiosInstance.get("/api/lots/lot_filter_options" + `?lot_type=${type?.toLocaleLowerCase()}`).then((res) => {
                if (res.status === 200) {
                    let options = formatfilterOptions(res.data?.filter)
                    this.categories_options =  options?.categories
                    this.conditions_options = options?.conditions
                    this.location_options = options?.location
                    this.sub_categories_options = options?.sub_categories

                    if(res?.data?.filter?.price_range) {
                        const { max_price } = res.data.filter.price_range;
                        const priceRange = [0 , (Math.ceil(max_price / 100000) * 100000) ?? 12000000]
                        this.priceRange = priceRange
                    } else {
                        this.priceRange = [0, 12000000]
                    }
                }
              });
        },
        scbLotFilterOptions(){
            scbAxiosInstance.get("/api/external_service/lots_filter_options")
            .then(res =>{
                if(res.status === 200){
                    let options = formatfilterOptions(res.data?.filter)
                    this.categories_options =  options?.categories
                    this.conditions_options = options?.conditions
                    this.location_options = options?.location
                    this.sub_categories_options = options?.sub_categories
                }
            })
        }
    },
    persist: {
        paths: ["biddingMode"]
    }
})
