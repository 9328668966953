<template>
  <section class="faq">
    <v-container>
      <h6 class="faq-title mb-10">
        Blubirch 
      </h6>
      <h6 class="faq-title mb-10">
        Lot Level Simplified - T&Cs for Blubirch Lots
      </h6>
      <h6 class="faq-ques" >Bid Value</h6>
      <p>
        Bid value is inclusive of GST however it doesn’t include the logistics cost.
      </p>
      <h6 class="faq-ques">Cancellation</h6>
      <p>
        Once a you have won the lot you have to honour the payment. Failure in doing so could result in penalty and/or “blacklisting” on the platform. Blubirch have the right, at our sole discretion, to refuse or cancel any auctioned lot or part of it for any reason. In the event that an auctioned lot be cancelled, we will notify you by email/phone/SMS/WhatsApp of such cancellation and your payment will be refunded within 7 business days(t&C apply).
      </p>

      <h6 class="faq-ques">Payment terms and purchase</h6>
      <p>
        Once your bid is approved and you are declared the winner of the relevant bid, you would be required to pay the total bid price/value within a period of 2 hours from the time of approval of your bid, failing which the bid shall stand cancelled (payment value depends seller wise).
      </p>

      <p>
        Subject to you making the payment of bid price/value within the specified seller wise period, you will receive information from us on when and how to place your vehicle to pick up your inventory.
      </p>

      <p>
        In case you fail to pick-up the goods within the said period, then the bid shall stand cancelled and we will be entitled to retain your total paid amount as cancellation charges, and this amount will be non-refundable under any circumstances.
      </p>

      <h6 class="faq-ques">Claims:</h6>
      <h6 class="faq-ques">Self-Pickup</h6>

      <p>Claims can be filed ONLY for shortage of items when the inventory is being picked up from the Blubirch warehouse.</p>
      <p>The buyer or buyer’s representative should clearly account for all the items included in the winning lot and what they are picking up. If there is a shortage of units, the buyer should inform Blubirch representative immediately. The details of the shortage such as number and type of shortage should be noted on the invoice document given to the buyer at the pick-up location itself.</p>

      <p class="faq-ques">3P Dispatch</p>
      <p>If 3P logistics is used for shipping the units from Blubirch to buyer, no claim in any form will be entertained. </p>



      <h6 class="faq-title mb-10 mt-10">TATA CLiQ</h6>
      <h6 class="faq-title mb-10">Lot Level Simplified T&Cs for TATA CLiQ Lots</h6>
      <p class="faq-ques">Bid Value</p>
      <p>Bid value is inclusive of GST however it doesn’t include the logistics cost.</p>

      <p class="faq-ques">Cancellation</p>
      <p>Once a you have won the lot you have to honour the payment. Failure in doing so could result in penalty and/or “blacklisting” on the platform. Blubirch (Seller) have the right, at our sole discretion, to refuse or cancel any auctioned lot or part of it for any reason. In the event that an auctioned lot be cancelled, we will notify you by email/phone/SMS/WhatsApp of such cancellation and your payment will be refunded within 24 hours.</p>

      <p class="faq-ques">Payment Terms</p>
      <p>Once you have been informed of winning an auctioned lot, you have to make full payment in 24 hours. If you fail to make payment in time, then Blubirch or Seller reserve the right to cancel the auctioned lot or offer it to another interested party. However, failure in making payment by you can result in penalty and/or “blacklisting” on the platform. Blubirch may revise the payment terms from time to time in its sole and absolute discretion; provided, however, that any change to payment terms will not be effective for any then-pending Sale, but will only be effective for the next subsequent sale.</p>
 
 
      <h6 class="faq-title mb-10 mt-10">Ultron</h6>
      <h6 class="faq-title mb-10">Lot Level Simplified T&Cs for Udaan Lots</h6>
      <p class="faq-ques">Bid Value</p>
      <p>Bid value is inclusive of GST however it doesn’t include the logistics cost.</p>

      <p class="faq-ques">Cancellation</p>
      <p>Once you have won the lot you have to honour the payment. Failure in doing so could result in penalty and/or “blacklisting” on the platform. Blubirch or Ultron have the right, at our sole discretion, to refuse or cancel any auctioned lot or part of it for any reason. In the event that an auctioned lot be cancelled, we will notify you by email/phone/SMS/WhatsApp of such cancellation and your payment will be refunded within 24 hours.</p>

      <p class="faq-ques">Payment Terms</p>
      <p>Once you have been informed of winning an auctioned lot, you have to make full payment in 24 hours. If you fail to make payment in time, then Blubirch or Ultron reserve the right to cancel the auctioned lot or offer it to another interested party. However, failure in making payment by you can result in penalty and/or “blacklisting” on the platform. Blubirch may revise the payment terms from time to time in its sole and absolute discretion; provided, however, that any change to payment terms will not be effective for any then-pending Sale, but will only be effective for the next subsequent sale.</p>
      <p>You will be required to pay the amount representing the bid price/value within 4 hours from the time of communication from our side requiring you to pay complete amount. In case you do not pay the amount within 4 hours from the date of communication for the same from our side, the bid shall stand cancelled and we will not be liable to return anything to you, </p>
      <p>Subject to you making the payment within the above-mentioned period, in case you do not pick up the goods on the date specified by us to you, you may collect the same only within 7 days from the date of collection specified by us by paying such logistics, warehousing and holding charges as specified by us. In case you fail to pick-up the goods within the said period, then the bid shall stand cancelled and we will be entitled to retain your total paid amount as cancellation charges, and this amount will be non-refundable under any circumstances.</p>

      <h6 class="faq-title mb-10 mt-10">Croma</h6>
      <h6 class="faq-title mb-10">Lot Level Simplified T&Cs for Croma Lots</h6>
      <p class="faq-ques">Bid Value</p>
      <p>Bid value is inclusive of GST however it doesn’t include the logistics cost.</p>

      <p class="faq-ques">Cancellation</p>
      <p>Once a you have won the lot you have to honour the payment. Failure in doing so could result in penalty and/or “blacklisting” on the platform. Croma (Seller) or Blubirch have the right, at our sole discretion, to refuse or cancel any auctioned lot or part of it for any reason. In the event that an auctioned lot be cancelled, we will notify you by email/phone/SMS/WhatsApp of such cancellation and your payment will be refunded within 24 hours.</p>

      <p class="faq-ques">Payment Terms</p>
      <p>Once you have been informed of winning an auctioned lot, you have to make full payment in 24 hours. If you fail to make payment in time, then Blubirch or Croma reserve the right to cancel the auctioned lot or offer it to another interested party. However, failure in making payment by you can result in penalty and/or “blacklisting” on the platform. Blubirch may revise the payment terms from time to time in its sole and absolute discretion; provided, however, that any change to payment terms will not be effective for any then-pending sale, but will only be effective for the next subsequent sale.</p>
      <p>You will be required to pay the amount representing the bid price/value within 4 hours from the time of communication from our side requiring you to pay complete amount. In case you do not pay the amount within 4 hours from the date of communication for the same from our side, the bid shall stand cancelled and we will not be liable to return anything to you, </p>
      <p>Subject to you making the payment within the above-mentioned period, in case you do not pick up the goods on the date specified by us to you, you may collect the same only within 7 days from the date of collection specified by us by paying such logistics, warehousing and holding charges as specified by us. In case you fail to pick-up the goods within the said period, then the bid shall stand cancelled and we will be entitled to retain your total paid amount as cancellation charges, and this amount will be non-refundable under any circumstances.</p>

      <h6 class="faq-title mb-10 mt-10">Flipkart Inventory</h6>
      <h6 class="faq-title mb-10">Lot Level Simplified T&Cs for eCom Inventory Lots</h6>

      <p class="faq-ques">Bid Value</p>
      <p>Bid value is inclusive of GST however it doesn’t include the logistics cost.</p>

      <p class="faq-ques">Cancellation</p>
      <p>Once a you have won the lot you have to honour the 100% payment. Failure in doing so could result in penalty and/or “blacklisting” on the platform.  Seller or Blubirch have the right, at our sole discretion, to refuse or cancel any auctioned lot or part of it for any reason. In the event that an auctioned lot be cancelled, we will notify you by email/phone/SMS/WhatsApp of such cancellation and your payment will be refunded within 4 hours, else it will be available in the Blubirch books of accounts and can be utilised for buying next LOT.</p>
  
      <p class="faq-ques">Payment terms and purchase</p>
      <p>Once your bid is approved and you are declared the winner of the relevant bid, you would be required to pay 100% of the total bid price/value within a period of 4 hours from the time of approval of your bid, failing which the bid shall stand cancelled</p>
      <p>You will be required to pay the amount representing the bid price/value within 4 hours from the time of communication from our side requiring you to pay complete amount. In case you do not pay the amount within 4 hours from the date of communication for the same from our side, the bid shall stand cancelled and we will not be liable to return anything to you, </p>
      <p>Subject to you making the payment within the above-mentioned period, in case you do not pick up the goods on the date specified by us to you, you may collect the same only within 7 days from the date of collection specified by us by paying such logistics, warehousing and holding charges as specified by us. In case you fail to pick-up the goods within the said period, then the bid shall stand cancelled and we will be entitled to retain your total paid amount as cancellation charges, and this amount will be non-refundable under any circumstances. </p>

      <h6 class="faq-title mb-10 mt-10">Amazon</h6>
      <h6 class="faq-title mb-10">Lot Level Simplified T&Cs for Amazon Lots</h6>

      <p class="faq-ques">Bid Value</p>
      <p>Bid value is inclusive of GST however it doesn’t include the logistics cost.</p>

      <p class="faq-ques">Cancellation</p>
      <p>Once a you have won the lot you have to honour the payment. Failure in doing so could result in penalty and/or “blacklisting” on the platform. Amazon (Seller) or Blubirch have the right, at our sole discretion, to refuse or cancel any auctioned lot or part of it for any reason. In the event that an auctioned lot be cancelled, we will notify you by email/phone/SMS/WhatsApp of such cancellation and your payment will be refunded within 24 hours, else it will be available in the Blubirch books of accounts and can be utilised for buying next LOT.</p>

      <p class="faq-ques">Payment terms and purchase</p>
      <p>Once your bid is approved and you are declared the winner of the relevant bid, you would be required to pay advance of the total bid price/value within a period of 24 hours from the time of approval of your bid, failing which the bid shall stand cancelled</p>
      <p>You will be required to pay the balance of the amount representing the bid price/value within 24 hours from the time of communication from our side requiring you to pay the remaining amount. In case you do not pay the remaining amount within 7 days from the date of communication for the same from our side, the bid shall stand cancelled and we are not liable to refund any advance and all the advances shall stand forfeited.</p>
      <p>Subject to you making the payment within the above-mentioned period, in case you do not pick up the goods on the date specified by us to you, you may collect the same only within 7 days from the date of collection specified by us by paying such logistics, warehousing and holding charges as specified by us. In case you fail to pick-up the goods within the said period, then the bid shall stand cancelled and we will be entitled to retain your total paid amount as cancellation charges, and this amount will be non-refundable under any circumstances.</p>

      <p class="faq-ques">Item Condition</p>
      <p>Amazon inventory will be in “AS IS” Open Box condition, which means the products may be functionally good with Minor or Major Scratches, Defective or damaged condition. The inventory will be shipped to the buyer in “As-Is” condition.</p>

      <p class="faq-ques">Shipment</p>
      <p>Post payment for the won lot, buyer will receive the invoice and information for inventory pickup. Logistics cost has to be borne by the buyer over and above the bid value.</p>

      <p class="faq-ques">Claims</p>
      <p class="faq-ques">Claims process:</p>
      <p> Claims can be processed only in the below-mentioned cases with Amazon:</p>
      <ol>
        <li>Less number of Master Cartons received as per POD.</li>
        <li>Master Cartons received in open condition</li>
        <li>Units inside the box are fake.</li>
        <li> Empty unit box received.</li>
        <li> Mismatch units received</li>
        <li>Missing units</li>
      </ol>

      <p class="faq-ques">How to claim?</p>
      <p>Claim must be submitted within 3 days of material receipt. </p>

      <p class="faq-ques">The following process must be followed while raising claims with Amazon, negligence will be a confirm rejection of claims.</p>
      <p>We would require a single quality, uncut, unedited and authentic video to file claims with Amazon.</p>
      <ol>
        <li>Video should start from unsealing of the vehicle including unloading of inventory, unboxing the master cartons i.e. starting from opening the master carton showing AWB & LPN Number. The video should also include counting and opening of each product/unit/box by clearly showing LPN number under the video camera with the AWB number and SLAM label clearly visible. Buyer is required to carefully take out each unit one by one under the uncut video recording.</li>
        <li>It should clearly show that the box is being opened for the first time with Amazon seal tape visible on all 6 sides.</li>
        <li>Fake Units : Identify fake units and show them very clearly under the camera.</li>
        <li>Take images of fake products from 6 sides - top, bottom, and four sides</li>
        <li>Mark the images with missing attributes or additional attributes that indicate that the unit is fake. For example, if the serial number is normally available on the original unit but not available on units delivered by Amazon, you must highlight in the image where the serial number is missing.</li>
      </ol>

      <p class="faq-ques">Claim process for Heavy and Bulky units:  </p>
      <p>The following points to be ensured while claiming for Heavy and Bulky units. Disputes should be raised within 3 days of handover from Amazon/Blubirch.</p>
      <ol>
        <li>Vehicle Open Video – You must open the seal of the vehicle under the camera clearly and unload all the units without any video cuts. Seal is usually of Amazon's 3rd party warehouse partner, few vehicles might have Amazon seal as per 3rd party warehouse partner contract with Amazon. Both the vehicle and papers will have the same Seal</li>
        <li>Unboxing Video of Units – Unbox all the units under the camera. Grading stickers should be clearly visible if present on the box (A, B, BER etc). All Large Appliances & Washing Machines will have grading sticker (expect TV and sealed item/UHI)</li>
        <li>Six side photos – Please take video from six sides for mismatch units. Please note different outdoor and indoor units in an Air conditioner won’t be considered as mismatch and no claims will be entertained for such cases. Sharing videos is recommended so that Amazon can investigate the disputes in-depth. However, if variation is high, this can be taken up on a case to case basis.</li>
        <li>No Claim for Damaged Units - No claim will be entertained for damaged units.</li>
      </ol>

      <p class="faq-ques">Points to be noted:</p>
      <ol>
        <li>Approval or Rejection of claims is purely Amazon/Seller decision. However, we always try our best to ensure timely approval of claims.</li>
        <li>Update on any status of claims usually takes 30+ business days.</li>
        <li>Once claims are approved, you can expect a credit note within 3-5 working days.</li>
        <li>Individuals who take delivery should produce a valid government ID proof as KYC document (Aadhar card/Driving license/Voter Id/Pan Card)</li>
        <li>B4 Traders do not encourage door dispatches/delivery.</li>
        <li>B4 Traders do not hold any responsibility for units under the master cartons boxes which are in intact condition.</li>
        <li>Buyer has all the privileges to validate, accept/reject the open or tampered inventory in our warehouses (T&C applies). Refunds for any rejected inventory will be only credited after the rejected inventory is sold to another buyer.</li>
        <li>Any transportation must have a equipped closed container, this is required to seal the vehicle after loading the inventory from our warehouses. Unboxing of video should be initiated from opening of this sealed container.</li>
        <li>Buyer or their representative is required to count the boxes, validate and sign the gate pass by reading all the entered information in it, also the concerned person is required to read all the associated documents like Invoice & E-Way Bill before departure from our warehouses.</li>
        <li>B4 Traders does not provide any shipment or transportation services but can assist you with contacts (if available). Kindly note that B4 Traders is not accountable for any mishaps during the shipment/transportation/in transit.</li>
        <li>Amazon will not accept disputes with shrink wrap for the shipment received. So, the unboxing video of the master carton should be shared after removing the shrink wrap. Please ensure that there is no shrink wrap or cello tape in the unboxing videos on the master carton.</li>
        <li>Amazon needs one unboxing video of the entire package and not multiple videos.</li>
        <li>Videos and photos can be shared through a Google Drive link, when the file size is large.</li>
        <li>Self pick up from Amazon Warehouse only for Heavy & Bulky units</li>
        <li>Blubirch will confirm when to pick up the units.</li>
        <li>No one is allowed inside Amazon warehouse</li>
        <li>Warehouse will seal the vehicle and hand over the required documents.</li>
      </ol>

      <p>You can share all the details with us at support@b4traders.com, and share the video over Google link.</p>
      <p>You can reach out to us on 1800 419 0431 or <a href="mailto:support@b4traders.com">support@b4traders.com</a> for any concerns.</p>

      <p class="faq-ques"><u>Payment & Dispatch Turn Around Time - Seller </u></p>
      <p class="faq-ques">Flipkart:</p>
      <ol>
        <li>Please make payment within 4 hours to avoid cancellation.</li>
        <li>Dispatch is expected between 4 to 6 business days from the date of payment. Delay in dispatch plan, may cause the buyer to bear the logistics cost.</li>
        <li>Buyers must pick the inventory directly from Flipkart Warehouse.</li>
        <li>Visual check can be done for up to 10% to 15% of inventory.</li>
        <li>No units can be rejected unless confirmed by POC.</li>
        <li>Rejections to be done from the checked inventory in the warehouse only, on the following conditions:
          <ol>
            <li>Product missing</li>
            <li>Product mismatch</li>
            <li>Heavily damaged</li>
            <li>Glass broken</li>
          </ol>
        </li>
        <li>No claims will be entertained once the vehicle has been moved out of the Warehouse</li>
        <li>Condition of inventory - AS-IS basis.</li>
      </ol>

      <p class="faq-ques">Pigeon:</p>
      <ol>
        <li>Please make payment within 24 hours to avoid cancellation.</li>
        <li>No Claims and No Warranty applicable to the inventory.</li>
        <li>Dispatch is expected between 15 to 16 business days from the date of payment</li>
        <li>Buyers must pick up the inventory directly from Pigeon Warehouse</li>
        <li>Condition of inventory is brand new seal packed inventory</li>
      </ol>

      <p class="faq-ques">Ultron:</p>
      <ol>
        <li>Please make payment within 24 hours to avoid cancellation.</li>
        <li>Visual checks from above can be done for the limited units.</li>
        <li>No units can be rejected unless confirmed by POC.</li>
        <li>No claims will be entertained once the vehicle has been moved out of Warehouse</li>
      </ol>

      <p class="faq-ques">Croma, Pegasus & Voltas</p>
      <ol>
        <li>Please make payment within 24 hours to avoid cancellation.</li>
        <li>No Claims and No Warranty applicable to the inventory.</li>
      </ol>

      <p class="faq-ques">ValueKart:</p>
      <ol>
        <li>All the units can be checked at warehouse</li>
        <li>Functionality check is allowed for high-value items and electronic goods only.</li>
        <li>Pick-up can be done only between 11 am - 5:30 pm, and may vary depending on the situation.</li>
        <li>Dispatch is expected between 5 to 7 business days from the date of payment</li>
        <li>Buyer is given specific time for inspection.</li>
        <li>No claims will be entertained once the vehicle has been moved out of the Warehouse</li>
        <li>Condition of inventory - AS-IS basis.</li>
      </ol>

      <p class="faq-ques">Amazon:</p>
      <ol>
        <li>Please make payment within 24 hours to avoid cancellation.</li>
        <li>Condition of inventory - AS-IS basis.</li>
        <li>These items have not been inspected by the Bulk 4Traders team nor have been physically inspected [SP1] or functionally checked. These are being sold in “As-is'' condition. Some of these items could be working order while some may not be fully functional. These may not come with original packaging, manuals, and/or supplementary accessories such as batteries and chargers.</li>
        <li>Since this inventory is AS-IS, please expect some level of damage and defects in the received inventory.</li>
        <li>Claims - A claim can be lodged with Amazon, The Blubirch team will update and close the claim case as per Amazon's process. The decision of claims will be informed to the buyers after getting the status from Amazon.</li>
      </ol>

      <p class="faq-ques"><u>Reseller's Disclaimer Information:</u></p>
      <p><strong>MRP/MOP:</strong>MRP may vary from the details mentioned in the manifest. The MRP/MOP in the manifest is only for the indication to the buyer.  </p>
      <p><strong>Images:</strong>Images provided are subjected to representation purposes only. The actual product may differ slightly from the images provided. Please refer to the manifest for actual product details.</p>
      <p><strong>Serial number (if not available in the listing): </strong>At times, the product may be available without a serial number and needs to be accepted accordingly. </p>

      <p class="faq-ques">Item Condition (Definitions), applies for all sellers and marketplaces.</p>
      <p><u>Brand New</u></p>
      <p>A brand-new, unused, unopened item in its original packaging, with all original packaging materials included. Packaging might have minor scratches or damages. Since it is seal packed, functional & physical condition of the item is not checked by Bulk4Traders team</p>


      <p><u>Open Box</u></p>
      <p>An apparently untouched item in perfect condition and fully functional. The original packaging is intact but could have minor scratches or damages. There are absolutely no signs of wear on the item. The product has been tested by the Bulk4Traders team</p>

      <p><u>Very Good</u></p>
      <p>The product is well-cared-for and is fully functional but may show minor physical or cosmetic blemishes and/ or the item may have some accessories missing. The packaging might have been replaced to protect the item. The product has been tested by the Bulk4Traders team</p>

      <p><u>Good</u></p>
      <p>The item shows normal marks from consistent use, but it remains in good condition and works fully. It may show other signs of previous use and/ or the item may have some accessories missing. The packaging might be missing or might have major damages. The product has been tested by the Bulk4Traders team.</p>

      <p><u>Acceptable</u></p>
      <p>The product may have minor functional issues and/ or the item is fairly worn. Signs of wear can include aesthetic issues such as scratches, dents, worn corners and cracks/damage on body. The item may have identifying markings on it or show other signs of previous use. Packaging may or may not be present and packaging condition may be bad. The product has been tested by the Bulk4Traders team.</p>

      <p><u>Defective</u></p>
      <p>The product may be functionally defective and/ or physically damaged. Packaging may or may not be present and packaging condition may be bad. The product has been tested by the Bulk4Traders team.</p>

      <p><u>Not Tested/AS - IS</u></p>
      <p>These items have not been inspected by Bulk4Traders team or have been physically inspected but not functionally checked. These are being sold in “As-is” condition. Some of these items could be working order while some may not be fully functional. These may not come with original packaging, manuals and/or supplementary accessories such as batteries and chargers</p>


      <p class="faq-ques">Shipment</p>
      <p>Post payment for the won lot, buyer will receive the invoice and information for inventory pickup. Logistics cost has to be borne by the buyer over and above the bid value.</p>


      <p class="faq-ques">DND DECLARATION</p>
      <p><b> I agree and consent to receive all communications at the mobile number provided, even if this mobile number is registered under DND/NCPR list under TRAI regulations. And for that purpose, I further authorize the Company to share/disclose the information to any third party service provider or any affiliates, group companies, their authorized agents or third party service providers. </b></p>
    </v-container>
  </section>
</template>

