import { axiosInstance, scbAxiosInstance } from "../services";

export const buyBid = async (body) => {
    const resp = await scbAxiosInstance.post(`/reseller/orders/checkout` , body)
    .then(res => {
        return res;
    }).catch((e) => {
        return e.response
    })
    return resp;
}


export const hybridBuy = async (body, params = {}) => {
    const resp = await axiosInstance.post(`/api/bids/check_out`, { ...body }, params)
    .then(res => {
        return res;
    }).catch((err) => {
        if (err?.response?.data?.errors) {
            alert(err.response?.data?.errors)
        } else if (err?.response?.status === 500) {
            alert("Something went wrong")
        }
        return err?.response
    })
    return resp;
}


export const fetchAllScbProducts = async (params) => {
    const resp = await scbAxiosInstance.get(`/api/external_service/get_manifest_detail`, { params })
    .then(res => {
        return res;
    }).catch((e) => {
        return e.response
    })
    return resp;
}


export const fetchOpenLotProducts = async (id, bid_id, isInterval) => {
    let url = `/api/external_service/get_lot_details?id=${id}&bid_id=${bid_id}`;
    if(isInterval) {
        url = url + `&autorefresh=active`
    }

    const resp = await scbAxiosInstance(url)
    .then(res => {
        return res;
    }).catch((e) => {
        return e.response
    })
    return resp;
}