import { defineStore } from "pinia";

export const usePaginationStore = defineStore({
  id: "pagination",

  state: () => ({
    page: 1,
    perPage: 24,
    totalItems: 0,
    productPage: 1,
    productTotal: 0,
  }),

  getters: {
    totalPages(state) {
      return Math.ceil(state.totalItems / state.perPage);
    },
    productPages(state) {
      return Math.ceil(state.productTotal / state.perPage);
    },
    offset(state) {
      return (state.page - 1) * state.perPage;
    },

    limit(state) {
      return state.perPage;
    },
  },

  actions: {
    setProductPage(value) {
      this.productPage = parseInt(value);
    },
    setProductTotalItems(value) {
      this.productTotal = value;
    },
    setPage(value) {
      this.page = parseInt(value);
    },

    setPerPage(value) {
      this.perPage = value;
    },

    setTotalItems(value) {
      this.totalItems = value;
    }
  }
})
