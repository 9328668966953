import { defineStore } from "pinia";
import { scbAxiosInstance } from "../services";

export const useAddressStore = defineStore({
  id: "addressStore",
  state: () => ({
    states: []
  }),

  actions: {
    fetchStates() {
      scbAxiosInstance.get("/api/addresses/get_states")
      .then((res) => {
        if (res.status === 200) {
          this.states = res.data.states
        }
      }).catch((err) => {
      })
    }
  },

  persist: {
    paths: ["states"]
  }
})
