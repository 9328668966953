import { axiosInstance, scbAxiosInstance } from "../services";

export const fetchMarketplaces = async (params = {}) => {
    const resp = await scbAxiosInstance.get("/api/external_service/get_marketplaces", {params})
    .then(res => {
        return res;
    }).catch((e) => {
        return e.response
    })
    return resp;
}


export const fetchOrganisationTc = async (id) => {
    const resp = await scbAxiosInstance.get(`/api/organizations/${id}/terms_of_purchase`).then(res => {
        return res
    }).catch(error => {
        return error.response
    })

    return resp;
}

export const fetchScbTermsOfPurchase = async (id) => {
    let result = await scbAxiosInstance.get(`/api/organizations/${id}/terms_of_purchase`).then(res => {
        return res.data?.terms_of_purchase
    }).catch(error => {
        return error.response.data
    })
    
    return result
}

export const fetchScbFaq = async (id) => {
    let result = await scbAxiosInstance.get(`/api/organizations/${id}/get_org_faq`).then(res => {
        return res.data?.faq
    }).catch(error => {
        return error.response.data
    })
    
    return result
}
