import axios from "axios"
import { scbURl } from "../services"

import { defineStore } from "pinia";
export const useCommonStore = defineStore({
    id: "commonStore",
    state: () => ({
        currentTime: ""
    }),
    actions: {
        async getCurrentTime() {
            const response = await axios.get(`${scbURl}/current_time.json`);
            let time = parseInt(response.data.time) * 1000;
            if (response.status === 200 && time) {
                this.currentTime = time
            }
            setInterval(() => {
                if (time) {
                    time = time + 1000;
                    this.currentTime = time
                }
            }, 1000);
        }
    }
})
