import { scbAxiosInstance } from "../services";

export const getUserDetails = async (params = {}) => {
    const resp = await scbAxiosInstance.get(`/reseller_registrations/get_reseller_profile.json`, params).then(res => {
        return res
    }).catch(error => {
        return error.response
    })

    return resp;
}


export const getBankDetails = async (params = {}) => {
    const resp = await scbAxiosInstance.get(`/api/external_service/get_neft_details`, params).then(res => {
        return res
    }).catch(error => {
        return error.response
    })

    return resp;
}

export const getStates = async (params = {}) => {
    const resp = await scbAxiosInstance.get(`/api/addresses/get_states`, params).then(res => {
        return res
    }).catch(error => {
        return error.response
    })

    return resp;
}

export const getCitiesByState = async (params = {}) => {
    const resp = await scbAxiosInstance.get(`/api/addresses/get_cities`, {params}).then(res => {
        return res
    }).catch(error => {
        return error.response
    })

    return resp;
}

export const updateProfile = async (data = {}) => {
    const resp = await scbAxiosInstance.post(`/reseller_registrations/buyer_profile_setup`, data).then(res => {
        return res
    }).catch(error => {
        return error.response
    })

    return resp;
}

export const updateProfileDocuments = async (data = {}) => {
    const resp = await scbAxiosInstance.post(`/reseller/users/upload_documents`, data).then(res => {
        return res
    }).catch(error => {
        return error.response
    })

    return resp;
}


export const getDocumentList = async (params = {}) => {
    const resp = await scbAxiosInstance.get(`/api/users/get_document_categories_list`, params).then(res => {
        return res
    }).catch(error => {
        return error.response
    })

    return resp;
}

export const getInterests = async (params = {}) => {
    const resp = await scbAxiosInstance.get(`/api/users/get_user_interests_list`, params).then(res => {
        return res
    }).catch(error => {
        return error.response
    })

    return resp;
}

export const contactUsQuery = async (data = {}) => {
    const resp = await scbAxiosInstance.post(`/reseller_registrations/website_contact`, data).then(res => {
        return res
    }).catch(error => {
        return error.response
    })

    return resp;
}
