import { defineStore } from "pinia";
import { scbAxiosInstance } from "../services";
import {useAlertStore} from "../stores";
import { defaultError} from "../static/index"

export const useAuth = defineStore({
  id: "authStore",
  state: () => ({
      user: "",
      dialog: false,
      forgotPasswordDialog: false,
      passwordOTPDialog: false,
      forgotPasswordUser: "",
      changePasswordDialog: false,
      otpLoginDialog: false,
      createPasswordDialog: false,
      profileDialog: false,
      transactionToken: ""
  }),

  actions: {
    signOut(invalidToken = false, showMsg = true) {
      this.user = ""
      const alertStore = useAlertStore();
      if(invalidToken) {
        alertStore.setAlertMessage('error', 'Session has been expired.', true)
      } else if(showMsg) {
        alertStore.setAlertMessage('success', 'Signed out successfully.', true)
      }
    },
    setUser(user = {}) {
      this.user = user;
    },
    setOtpLoginDialog(value) {
      this.otpLoginDialog = value;
    },

    setProfileDialog(value) {
      this.profileDialog = value;
    },

    setChangePasswordDialog(value) {
      this.changePasswordDialog = value;
    },

    setCreatePasswordDialog(value) {
      this.createPasswordDialog = value;
    },

    setForgotPasswordUser(value) {
      this.forgotPasswordUser = value
    },

    setLoginDialog(value) {
      this.dialog = value
    },

    setPasswordOTPDialog(value) {
      this.passwordOTPDialog = value
    },

    setForgotPasswordDialog(value) {
      this.forgotPasswordDialog = value
    },

    setTransactionToken(value) {
      this.transactionToken = value
    },
    resendLoginOtp() {
      const alertStore = useAlertStore();
      scbAxiosInstance.post("/api/users/resend_otp", { mobile_number: this.forgotPasswordUser, "request_type": "reset_password" })
      .then((res) => {
        if (res.status === 200) {
          this.setOtpLoginDialog(true)
          this.setLoginDialog(false)
          this.setForgotPasswordUser(mobile)
          alertStore.setAlertMessage('success', res?.data?.message, true)
        }
      }).catch(err => {
        alertStore.setAlertMessage('error', err?.response?.data?.message || defaultError, true)
      })
    },
    mobileSignIn(mobile) {
      const alertStore = useAlertStore();
      return scbAxiosInstance.post("/api/users/send_login_otp", { mobile_number: mobile })
      .then((res) => {
        if (res.status === 200) {
          // this.setOtpLoginDialog(true)
          // this.setLoginDialog(false)
          this.setForgotPasswordUser(mobile)
          alertStore.setAlertMessage('success', res?.data?.message, true)
        }
        return res;
      }).catch(err => {
        alertStore.setAlertMessage('error', err?.response?.data?.message || defaultError, true)
      })
    },
    signIn(details) {
      const alertStore = useAlertStore();
      scbAxiosInstance.post("/api/buyers/sign_in", { login_params: details })
      .then((res = {}) => {
        if (res.status === 200) {
          this.user = res.data.user
          alertStore.setAlertMessage('success', 'Signed in successfully.', true)
        }
      }).catch(err => {
        alertStore.setAlertMessage('error', err?.response?.data?.error, true)
      })
    }
  },

  persist: {
    paths: ["user"]
  }
})
